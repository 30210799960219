import React,{useState} from 'react';
//import './Teams.css';
import {  FaChartBar,FaBalanceScale,FaLandmark, FaGavel,FaFileInvoiceDollar, FaBriefcase,FaFileSignature } from 'react-icons/fa';
//import { Modal, Form, Input,Button} from 'antd';
// import service1 from './service1.png';
// import service2 from './service2.png';
// import service3 from './service3.png';
import travelImg from '../images/travelImg.jpeg';
import Navbars from '../Navbar/Navbars';
import Footer from '../Footer/Footer';
import Profile from './Profile';
import sotonye from '../images/sotonye.jpeg';
import ProfileSO from './ProfileSO';
//import Team from '../Navbar/Team/Team';

const Sotonye = () => {
  const [hoveredService, setHoveredService] = useState(null);

  const handleServiceHover = (service) => {
    setHoveredService(service);
  };
  return (
    <>
    <Navbars/>
    <section className="services-section">

    <ProfileSO
  image={sotonye}
  name="Sotonye Belonwu"
  position="LLM(US). BL, LLB"
  phone="+ 1347 812-4471"
  email="leadsattorneysandsolicitors@gmail.com"
  linkedIn=" linkedin.com/in/sotonye-belonwu-441b09177.
  "
  achievements={[
    `Sotonye is a partner of the firm in the corporate, commercial and finance law practice unit of the firm.

    She holds a Master of Laws degree from the New York University, United State. She graduated from the University of Nigeria with a first-class honor and further graduated with a first-class honor from the Nigerian Law School. She is qualified to practice as a lawyer in Nigeria and the United State. She has had international experience working with top law firms in Nigeria and the United States.
    Before joining the firm, she had also gained competence in the international policy space having worked with the International Institute for Sustainable Development in Geneva Switzerland and the Asian Development Bank in Philippines over five years of practice experience in top tier law firms in Nigeria and in the United States.  She has won several awards and scholarship such as the Hauser Global Scholarship, a fully funded scholarship for an LLM program at the New York University, United States. Beyond core legal practice.
    `

  ]}
  point1={[`Project Finance.


  `]}
  point2={[` Banking and insurance


  `]}
  point3={[`Mergers and Acquisition transactions.

  `]}
//   point4={[`ADR dispute resolutions involving commercial parties.

//   `]}
//   point5={[`Management of vast real estate properties in various cities in Nigeria. `]}
//   point6={[`
//   `]}
/>

     <br/>
    
    </section>
    <Footer/>
    </>
  );
};

export default Sotonye;
