import React from "react";
import "./ComingSoon.css";

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <h1>Coming Soon</h1>
      <p>Our website is under construction</p>
    </div>
  );
};

export default ComingSoon;
