import React, { useState } from "react";
//import './Teams.css';
import {
  FaChartBar,
  FaBalanceScale,
  FaLandmark,
  FaGavel,
  FaFileInvoiceDollar,
  FaBriefcase,
  FaFileSignature,
} from "react-icons/fa";
//import { Modal, Form, Input,Button} from 'antd';
// import service1 from './service1.png';
// import service2 from './service2.png';
// import service3 from './service3.png';
import travelImg from "../images/travelImg.jpeg";
import Navbars from "../Navbar/Navbars";
import Footer from "../Footer/Footer";
import Profile from "./Profile";
import joshua from "../images/joshua.jpeg";
import ProfileDAV from "./ProfileDAV";
//import Team from '../Navbar/Team/Team';

const Joshua = () => {
  const [hoveredService, setHoveredService] = useState(null);

  const handleServiceHover = (service) => {
    setHoveredService(service);
  };
  return (
    <>
      <Navbars />
      <section className="services-section">
        <ProfileDAV
          image={joshua}
          name="JOSHUA CHUKWUDI"
          position="LLB, BL, LLM (UK) "
          phone="+234 9095440869 "
          email="joshuachukwudi555@gmail.com"
          linkedIn="https://www.linkedin.com/in/joshua-chukwudi-3a097b16a "
          achievements={[
            `Joshua is a partner of the firm, specializing in Corporate and Commercial Law,
Litigation, and Human Rights Law. He holds a Master of Laws (LLM) in
International Human Rights Law from the prestigious Nottingham Trent University,
United Kingdom, where he conducted in-depth research on the topic: &quot;Is killing an
effective means of combating terrorism? A case study of Boko Haram and Al Qaeda.&quot;

A graduate of the University of Nigeria with a Second Class Upper Division, Joshua
was called to the Nigerian Bar after completing the Nigerian Law School. With many
years of active practice in one of Abuja, Nigeria leading law firms, he has been
involved in handling high-profile cases across various practice areas.
Joshua’s expertise includes a wide array of corporate and commercial advisory
services, such as corporate due diligence and compliance, pre- and post-incorporation
matters, project financing advisory, litigation, and human rights enforcement.
     `,
          ]}
          point1={[
            `Corporate Compliance &amp; Advisory

  `,
          ]}
          point2={[
            `Drafting various legal agreements and documentation

  `,
          ]}
          point3={[
            `Human Rights Enforcement Disputes
  `,
          ]}
          point4={[
            `Pre- and Post-Incorporation Corporate Compliance

  `,
          ]}
          //         point5={[
          //           `Construction and joint venture
          // `,
          //         ]}
          //   point6={[`Preparation of legal opinions on different areas of laws, such taxation, real estate transactions, loan facilities etc.
          //   `]}
        />

        <br />
      </section>
      <Footer />
    </>
  );
};

export default Joshua;
