import React from "react";
import "./Profile.css";

const ProfileChioma = ({
  image,
  name,
  position,
  phone,
  email,
  linkedIn,
  achievements,
  point1,
  point2,
  point3,
  point4,
  point5,
  point6,
}) => {
  return (
    <div className="profile-container">
      <div className="profile-info">
        <img src={image} alt={name} />
        <h2 className="profile-name">{name}</h2>
        <p className="profile-position">{position}</p>
        <p className="profile-contact">{phone}</p>
        <p className="profile-contact">{email}</p>
        <p className="profile-contact">{linkedIn}</p>
      </div>
      <div className="profile-details">
        <h3>Profile</h3>
        <div className="profile-achievements">
          {achievements.map((achievement, index) => (
            <p key={index}>{achievement}</p>
          ))}
        </div>
        <h6>A SELECTION OF RELEVANT EXPERIENCES</h6>
        <ul className="profile-experiences">
          <li>{point1}</li>
          <li>{point2}</li>
          <li>{point3}</li>
          {/* <li>{point4}</li> */}
          {/* <li>{point5}</li> */}
        </ul>
      </div>
    </div>
  );
};

export default ProfileChioma;
