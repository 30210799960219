import React, { useState } from "react";
import "./Dispute.css"
import will from '../images/will.jpeg';
import arbitration from '../images/arbitration.jpeg';
import realEstate from '../images/realEstate.jpeg';
import tax from '../images/tax.avif';
import criminal from '../images/criminal.jpeg';
import commercial from '../images/commercial.jpeg'
import travelImg from "../images/travelImg.jpeg";
import Navbars from "../Navbar/Navbars";
import Footer from "../Footer/Footer";

const practiceAreas = [
  {
    id: 1,
    name: "DISPUTE RESOLUTION, LITIGATION AND ARBITRATION",
    image:arbitration,
    title: "Dispute Resolution",
    content: (
        <>
    We understand that for many reasons, dispute is a part of human interactions in different fields, such as business, marriage,
    and social relationships that exist. Our priority as a firm is to ensure that we manage our clients’ disputes in an efficient manner that practically protects the interests and value of our clients.<br/><br/>
    Our vast legal knowledge and practical experience enable us to critically analyze every situation presented to us by clients and to present effective practical solutions that will preserve our clients’ business goals or other objectives. The fact that we are a team of experts in different jurisdictions gives us the opportunity to leverage the world best practices in solving our clients’ problems. Besides representing clients at all levels of litigation, our lawyers are also skilled and certified negotiators and arbitrators who help clients resolve legal disputes efficiently. For this reason, whenever appropriate, we also recommend resolving disputes through alternative dispute resolutions, such as mediation or arbitration, as they have special benefits in certain circumstances.<br/><br/> Several of our team members are experienced certified arbitrators and mediators, and we have significant experience in representing clients in domestic and international arbitration proceedings, mediation, and negotiations.
    </>)
  },
  
  {
    id: 2,
    name: "REAL ESTATE AND CONVEYANCING",
    image: realEstate,
    title: "Real Estate and Conveyancing",
    content:(
        <>
      The real estate industry has become one that both corporations and individuals invest in at a remarkable rate. The objective of every party is usually to acquire good title over the property of interest, and failure to follow certain standards in real estate transactions often result in the risk of acquiring bad title to properties.  It has become very critical to engage skilled professionals to advise on each aspect of real estate transactions to avoid the potential risks that parties easily fall into. <br/><br/>
      At Leads Attorneys & Solicitors, we have a highly experienced team of property lawyers that represent clients on a wide range of property and conveyancing related matters. Our team engages in property and title search, negotiations on behalf of clients, preparation of all necessary documents, registration of titles, development projects, and management of real estate properties. We represent individuals, families, organizations, and companies in all types of real estate transactions, such as purchase, sale, mortgages, construction, and property management. We ensure that our clients deal only with property with good titles and no form of unrevealed encumbrance attached
      </> ),
  },
  {
    id: 3,
    name: "CRIMINAL LITIGATION",
    image: criminal,
    title: "Criminal Litigation",
    content:(
        <> 
      One of the cardinal rights vested on individuals in many civilized nations is the right to be deemed innocent to any accusation until proven guilty through a fair trial under the law. And the law gives every accused person the right to have a legal representative in the trial process. <br/><br/>
      At Leads Attorneys & Solicitors, we have experienced lawyers who understand the operations and urgency of criminal justice systems around the world. We strictly employ our best strategies in representing all our clients accused of any crime, to ensure that justice is manifestly seen to be done in every case.<br/><br/>
      Our team comprises of notable criminal law experts with a proven track record of representing clients in some of the most complex criminal law matters. We are skilled in analyses of all kinds of evidence, and engage in investigative litigation, which gives us the edge in criminal law matters. It is our practice to deploy every legal means to ensure that none of our clients is convicted of any criminal charge until proven beyond a reasonable doubt. We are also tactical in exploring the option of plea bargain in circumstances where it is favorable to our client.<br/><br/>
      We advise and represent clients on a whole spectrum of criminal charges ranging from, murder, theft and domestic abuse to drug trafficking and white-collar crimes<br/>
      </>),
  },
  {
    id: 4,
    name: "TAX LAWS",
    image: tax,
    title: "Tax Laws",
    content:(
        <>
      The scope and application of tax laws appears to be a complex issue to many businesses and individuals. And having the proper knowledge of the actual tax to pay is essential as it affects the income of businesses and individuals. For the above reason, proper tax planning, assessment and remittance becomes very essential to ensure that there is no form of cheating in the taxation process. <br/><br/>
      At Leads Attorneys & Solicitors, our tax practice team comprises of experts from a diverse range of backgrounds such as law, accounting, and banking. This distinguishes our tax services as we simplify the complex tax problems to clients to enable them to make informed decisions in every tax issues. <br/><br/>
      Our tax team is made up of certified tax practitioners and researchers who provides services such as complex tax planning, tax advisory, tax filing and representation in matters involving tax authorities. We also advise on the taxation of foreign businesses and individuals and taxation of foreign contracts in many countries. 
      </>),
  },
  {
    id: 5,
    name: "CORPORATE, COMMERCIAL LAW, AND TRADE",
    image: commercial,
    title: "Corporate, Commercial Law, and Trade",
    content:(
        <>
      At Leads Attorneys & Solicitors, we have an experienced and exceptional team responsible for the corporate, commercial and trade practice unit. Our team handles a different range of both local and cross-border corporate and commercial transactions. We have a remarkable record of providing clients with advisory services on all forms of business strategies which will be a reliable vehicle in achieving clients’ business goals and objectives. <br/><br/>
      We render various corporate, commercial and trade services ranging from incorporation of companies, restructuring of businesses, corporate financing, mergers and acquisition, corporate secretary services, corporate governance etc. <br/><br/> 
      Our sectoral experience includes banking, communication, building & construction, energy, food & beverage, healthcare, logistics, oil & gas, shipping, and many other kinds of businesses. <br/><br/>
      Our team can advise and help you prepare, negotiate, and execute a variety of commercial and corporate contracts documents, such as mortgage instruments, agency and distribution, bank loans and security documentations. We also prepare other legal documents such as joint venture and strategic alliances, partnerships and shareholders agreements, guarantees, charges, assignments, corporate governance, and compliance. Furthermore, we render services such as corporate secretarial services, corporate structuring and restructuring, due diligence, facilities management, franchise, insurance, intellectual property, mergers and acquisitions, purchase, and sale of businesses, etc.
      </>),
  },
  {
    id: 6,
    name: "WILLS, TRUST & PROBATE",
    image: will,
    title: "Wills, Trust & Probate",
    content:(
        <>
      Estate planning is one of the most sensitive areas of law which among other requirements demands that lawyers exercise extreme care in handling any responsibility for a client. The confidentiality, technicality and sensitive nature of estate planning makes it necessary that clients should choose trusted legal advisors to handle their affairs.<br/><br/> 
      At Leads Attorneys & Solicitors our team understands the very peculiar nature of estate planning and we deploy the best strategies in ensuring that every desired goal of all our clients are met, while planning their estate. We have a culture of advising our clients on a broad range of contentious issues in connection with estates, pension trusts, will, and probate matters.<br/><br/>
      We provide different services such as drawing up wills, application for probate, letters of administration, and legal representation in litigious cases involving allegations of fraudulent wills and contested probate proceedings.
      Our diligence and thorough legal knowledge in estate planning have helped us gain the trust of high-net-worth clients who entrust all their affairs and confidence in us for adequate representation. 
      </>),
  },
];

const PracticeArea = ({ name, id, handleClick, active }) => {
  return (
    <div
      onClick={() => handleClick(id)}
      className={`practice-area ${active ? "active" : ""}`}
    >
      {name}
    </div>
  );
};

const PracticeAreas = ({ handleClick, activeArea }) => {
   
  return (
    <div className="practice-areas">
        <h3 style={{borderBottom:'50px',}}>Practice Areas</h3>
      {practiceAreas.map((area) => (
        <PracticeArea
          key={area.id}
          id={area.id}
          name={area.name}
          handleClick={handleClick}
          active={activeArea === area.id}
        />
      ))}
    </div>
  );
};

const PracticeAreaDetails = ({ activeArea }) => {
    const currentArea = practiceAreas.find((area) => area.id === activeArea);
   
    return (
       
        
      <div className="practice-area-details">
        
        {currentArea && currentArea.id === activeArea && (
          <>
            <div className="practice-area-image">
              <img src={currentArea.image} alt={currentArea.title} style={{width:"100%",height:"auto"}} />
              <h2>{currentArea.title}</h2>
            </div>
            <div className="practice-area-content">
              <p>{currentArea.content}</p>
            </div>
          </>
        )}
      </div>
     
     
    );
  };
  
const RealEstate = () => {
const [activeArea, setActiveArea] = useState(1);

const handleClick = (id) => {
setActiveArea(id);
};

return (
    <>
    <Navbars/>
<div className="app">

<PracticeAreas handleClick={handleClick} activeArea={practiceAreas[1].id} />
<PracticeAreaDetails activeArea={practiceAreas[1].id} />

</div>
 <Footer/>
 </>
);
};

export default RealEstate;
   
