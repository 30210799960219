import React,{useState} from 'react';
//import './Teams.css';
import {  FaChartBar,FaBalanceScale,FaLandmark, FaGavel,FaFileInvoiceDollar, FaBriefcase,FaFileSignature } from 'react-icons/fa';
//import { Modal, Form, Input,Button} from 'antd';
// import service1 from './service1.png';
// import service2 from './service2.png';
// import service3 from './service3.png';
import travelImg from '../images/travelImg.jpeg';
import Navbars from '../Navbar/Navbars';
import Footer from '../Footer/Footer';
import Profile from './Profile';
import jessica from '../images/jessica.jpeg';
import ProfileSO from './ProfileSO';
//import Team from '../Navbar/Team/Team';

const Jessica = () => {
  const [hoveredService, setHoveredService] = useState(null);

  const handleServiceHover = (service) => {
    setHoveredService(service);
  };
  return (
    <>
    <Navbars/>
    <section className="services-section">

    <ProfileSO
  image={jessica}
  name="Chukwunonso Jessica Utomi"
  position="MSC (UK), BL, LLB  "
  phone=""
  email="leadsattorneysandsolicitors@gmail.com"
  linkedIn="  linkedin.com/in/chukwunonso-jessica-utomi-b1596319a

  "
  achievements={[
    `Jessica is a Partner of the firm in the Tax (corporate and personal tax), real estate and Hospitality practice unit of the firm.
    Jessica holds a master’s degree in hospitality management from Technology University Dublin, Ireland. She graduated from the Delta State University and Nigerian Law School in honors, and she is qualified to practice law in Nigeria.
    Before joining the firm, Jessica has had over five years of law practice experience in a top law firm in Lagos Nigeria. She served as the editor of Tax Law Report of Nigeria, one of the biggest tax law reporters in Nigeria.
     `

  ]}
  point1={[`Corporate and personal Tax



  `]}
  point2={[` Editor Tax law report.



  `]}
  point3={[`Hospitality Advisory


  `]}
//   point4={[`ADR dispute resolutions involving commercial parties.

//   `]}
//   point5={[`Management of vast real estate properties in various cities in Nigeria. `]}
//   point6={[`
//   `]}
/>

     <br/>
    
    </section>
    <Footer/>
    </>
  );
};

export default Jessica;
