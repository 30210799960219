import React, { useState } from "react";
//import './Teams.css';
import {
  FaChartBar,
  FaBalanceScale,
  FaLandmark,
  FaGavel,
  FaFileInvoiceDollar,
  FaBriefcase,
  FaFileSignature,
} from "react-icons/fa";
//import { Modal, Form, Input,Button} from 'antd';
// import service1 from './service1.png';
// import service2 from './service2.png';
// import service3 from './service3.png';
import travelImg from "../images/travelImg.jpeg";
import Navbars from "../Navbar/Navbars";
import Footer from "../Footer/Footer";
import Profile from "./Profile";
import chioma from "../images/chioma.jpeg";
import ProfileChioma from "./ProfileChioma";
//import Team from '../Navbar/Team/Team';

const Chioma = () => {
  const [hoveredService, setHoveredService] = useState(null);

  const handleServiceHover = (service) => {
    setHoveredService(service);
  };
  return (
    <>
      <Navbars />
      <section className="services-section">
        <ProfileChioma
          image={chioma}
          name="Chioma Azolike"
          position="LLB, BL. "
          phone="+234 7030222736 "
          email="leadsattorneysandsolicitors@gmail.com"
          linkedIn="https://www.linkedin.com/in/chioma-azolike-a7b59a16b "
          achievements={[
            `Chioma Azolike is a partner of the firm, specializing in Capital Markets, Mergers &amp;
Acquisitions, Corporate Finance, and Corporate and Commercial Law.
She holds a Second Class Upper Division degree from the University of Nigeria and
graduated with First Class Honors from the Nigerian Law School, making her a
qualified legal practitioner in Nigeria. With over six years of experience in the legal
profession, Chioma has built a robust career working with some of the top law firms
in Lagos.
Before joining the firm, Chioma gained invaluable experience across three prominent
law firms, where she successfully managed high-profile legal assignments. Her
expertise spans a wide range of corporate and commercial advisory services,
including the negotiation and drafting of loan agreements, mergers &amp; acquisitions,
bond issuance, and commercial papers. Chioma is well-versed in providing clients
with strategic legal advice on corporate restructuring, investments, and divestments.
Her experience also includes conducting thorough due diligence on state and
corporate entities, performing legal and regulatory research, and securing necessary
approvals. In addition to her advisory role, Chioma has offered company secretarial
services, attended client meetings, conducted extensive legal research, and prepared
detailed legal opinions.
Chioma was also an integral part of the team working on the Malala Fund Project, an
initiative in selected states in Nigeria’s North East and North West regions aimed at
promoting girls' education.
     `,
          ]}
          point1={[
            `Corporate Compliance &amp; Advisory

  `,
          ]}
          point2={[
            `Project Finance.

  `,
          ]}
          point3={[
            `Mergers and Acquisition transactions.
  `,
          ]}
          //           point4={[
          //             `Pre- and Post-Incorporation Corporate Compliance

          //   `,
          //           ]}
          //         point5={[
          //           `Construction and joint venture
          // `,
          //         ]}
          //   point6={[`Preparation of legal opinions on different areas of laws, such taxation, real estate transactions, loan facilities etc.
          //   `]}
        />

        <br />
      </section>
      <Footer />
    </>
  );
};

export default Chioma;
