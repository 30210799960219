import React, { useState,useRef } from 'react';
import emailjs from '@emailjs/browser';
import Navbars from '../../Components/Navbar/Navbars';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import travelImg from './Components/images/travelImg.jpeg';
import '../../App.css';
// import Button from 'react-bootstrap/Button';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import {  FaChartBar,FaBalanceScale,FaLandmark, FaGavel,FaFileInvoiceDollar, FaBriefcase,FaFileSignature } from 'react-icons/fa';
import { Modal, Form, Input,Button} from 'antd';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import slideImages from '../../Components/slideImages/slideImages';
import Team from '../../Components/Navbar/Team/Team';
import Footer from '../../Components/Footer/Footer';
import AboutUs from '../../Components/AboutUs/AboutUs';
import { Link } from 'react-router-dom';
//import Services from './Components/Services/Services';
function Home() {
  const [modalVisible, setModalVisible] = useState(false);
 // const [form] = Form.useForm();
  const form =useRef();

  const handleOpenModal =()=> {
    console.log('hi')
    setModalVisible(true);
  }

  const handleCloseModal =()=> {
    setModalVisible(false);
  }

  const handleSubmit =(e) => {
    //console.log(values);
    // you can add your form submission logic here
    e.preventDefault();

    emailjs.sendForm('service_db94e6l', 'template_6crjh8m', form.current, 'Of4Tpw7DNmIg80Ai1')
      .then((result) => {
          console.log(result.text);
          toast.success('Message submitted Successfully!');
          e.target.reset()
      }, (error) => {
          console.log(error.text);
      });
    handleCloseModal();
  }
  const [hoveredService, setHoveredService] = useState(null);

  const handleServiceHover = (service) => {
    setHoveredService(service);
  };
  return (
    <>
    <div className="Appss">
      <div className='tabNav'>
        
          <span className='tabInfo'>  <span className="divider"></span> 
      </span>
        
          <Button className='formContact' onClick={handleOpenModal}>
        Contact Us
      </Button>
         
        
      </div>

      <Navbars/>
      
      <Modal
        title="Contact Us"
        open={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
      >
       <form ref={form} onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <label style={{ marginBottom: '0.5rem' }}>Name</label>
      <input type="text" name="user_name" style={{ padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #ccc', marginBottom: '1rem' }} />

      <label style={{ marginBottom: '0.5rem' }}>Email</label>
      <input type="email" name="email" style={{ padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #ccc', marginBottom: '1rem' }} />

      <label style={{ marginBottom: '0.5rem' }}>Phone Number</label>
      <input type="text" name="phoneNumber" style={{ padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #ccc', marginBottom: '1rem' }} />

      <label style={{ marginBottom: '0.5rem' }}>Message</label>
      <textarea name="message" style={{ padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #ccc', marginBottom: '1rem' }}></textarea>

      <input type="submit" value="Send" style={{ backgroundColor: '#37AF60', color: 'white', padding: '0.5rem 1rem', borderRadius: '0.25rem', border: 'none', cursor: 'pointer' }} />
    </form>

      </Modal>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
    <div className="slide-container">
      {/* <Fade>
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <img style={{ width: '100%',height:'500px' }} src={slideImage.url} />
            <p className='typewriter'>{slideImage.caption}</p>
            
          </div>
        ))}
      </Fade> */}
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showArrows={true}
        showStatus={false}
        showIndicators={true}
        showThumbs={false}
        interval={3000}
      >
        {slideImages.map((slideImage, index) => (
          <div key={index} className='slider'>
            <img src={slideImage.url} alt={slideImage.caption} />
            <p className='typewriter'>{slideImage.caption}</p>
          </div>
        ))}
      </Carousel>
    </div><br/><hr/>
    <AboutUs/><hr/>

    <div className="services-container">
    <Link to={"/Dispute"}
        className={`service real-estate ${hoveredService === 'real-estate' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('real-estate')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaBalanceScale  size={100} className="service-icon" />
        <p>DISPUTE RESOLUTION,<br/> LITIGATION AND ARBITRATION</p>
      </Link>
      <Link to={"/RealEstate"}
        className={`service visa-processing ${hoveredService === 'visa-processing' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('visa-processing')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaLandmark  size={100} className="service-icon" />
        <p>REAL ESTATE AND CONVEYANCING</p>
      </Link>
      <Link to={"/CriminalLit"}
        className={`service advertisement ${hoveredService === 'advertisement' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('advertisement')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaGavel  size={100} className="service-icon" />
        <p>CRIMINAL LITIGATION</p>
      </Link>
      <Link to={"/TaxLaw"}
        className={`service web-development ${hoveredService === 'web-development' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('web-development')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaFileInvoiceDollar size={100} className="service-icon" />
        <p>TAX LAWS</p>
      </Link>
      <Link to={"/Corporate"}
        className={`service blogging ${hoveredService === 'blogging' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('blogging')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaBriefcase  size={100} className="service-icon" />
        <p>CORPORATE, COMMERCIAL LAW, AND TRADE</p>
      </Link>
      <Link to={"/Will"}
        className={`service marketing ${hoveredService === 'marketing' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('marketing')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaFileSignature  size={100} className="service-icon" />
        <p>WILLS, TRUST & PROBATE</p>
      </Link>
    </div><br/>
    <Team/><br/>
    <Footer/>
    
    </>
  );
}

export default Home;
