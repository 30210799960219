//import Button from 'react-bootstrap/Button';
//import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import emailjs from '@emailjs/browser';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import Form from 'react-bootstrap/Form';
import React, { useState,useRef } from 'react';
import Nav from 'react-bootstrap/Nav';
import '../../App.css';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
// import logo from '../logo.png';
import logo from '../images/logo.png';
import { Modal, Form, Input,Button} from 'antd';
import { Link } from 'react-router-dom';

function Navbars(props) { 
  const form =useRef();
  const handleServiceClick = (event) => {
    event.preventDefault();
    props.history.push("/Services");
  };
  // let navigate = useNavigate(); 
  // const routeChange = () =>{ 
  //   let path = `./Home.js/#action1`; 
  //   navigate(path);
  // }
  const [modalVisible, setModalVisible] = useState(false);
  //const [form] = Form.useForm();

  const handleOpenModal =()=> {
    console.log('hi')
    setModalVisible(true);
  }

  const handleCloseModal =()=> {
    setModalVisible(false);
  }

  const handleSubmit =(e) => {
    //console.log(values);
    // you can add your form submission logic here
    e.preventDefault();

    emailjs.sendForm('service_db94e6l', 'template_6crjh8m', form.current, 'Of4Tpw7DNmIg80Ai1')
      .then((result) => {
          console.log(result.text);
          toast.success('Message submitted Successfully!');
          e.target.reset()
      }, (error) => {
          console.log(error.text);
      });
    handleCloseModal();
  }
  return (
    <>
      {[ 'lg'].map((expand) => (
        <Navbar key={expand} bg="light" expand={expand} className="mb-3 checkB" >
          <Container fluid >
            <Navbar.Brand href="#">
            <img src={logo} alt="Limited" width='180px' height='auto'/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            className='bNav'>
              <Offcanvas.Header className='mNav' closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                 LAS
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body >
                <Nav className="justify-content-center flex-grow-1 pe-3">
                  <Nav.Link href="/">Home</Nav.Link>
                  {/* <Nav.Link href="#S">SERVICES</Nav.Link> */}
                  <Nav.Link href=""> <Link  style={{ textDecoration: 'none',color:"#373738" }} to='/Teams'>
           Legal Team
          </Link></Nav.Link>
                  {/* <Nav.Link href="#W">About Us</Nav.Link> */}
                  <Nav.Link href=""> <Link style={{ textDecoration: 'none',color:"#373738" }} to='/Services'>
           Practice Areas
          </Link></Nav.Link>
                  <NavDropdown
                    title="More"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                   
                  >
                    <NavDropdown.Item href=""> <Link style={{ textDecoration: 'none',color:"#373738" }} to='/Dispute'>
           DISPUTE RESOLUTION
          </Link></NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="">
                    <Link style={{ textDecoration: 'none',color:"#373738" }} to='/RealEstate'> REAL ESTATE AND CONVEYANCING</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="">
                    <Link style={{ textDecoration: 'none',color:"#373738" }} to='/CriminalLit'>CRIMINAL LITIGATION</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="">
                    <Link style={{ textDecoration: 'none',color:"#373738" }} to='/TaxLaw'>TAX LAWS</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="">
                    <Link style={{ textDecoration: 'none',color:"#373738" }} to='/Corporate'>CORPORATE, COMMERCIAL LAW</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="">
                    <Link style={{ textDecoration: 'none',color:"#373738" }} to='/Will'> WILLS, TRUST & PROBATE</Link>
                    </NavDropdown.Item>
                    
                  </NavDropdown>
                  <Nav.Link href=""> <Link style={{ textDecoration: 'none',color:"#373738" }} to='/ComingSoon'>
           News and Publication
          </Link></Nav.Link>
          <Nav.Link href=""> <Link style={{ textDecoration: 'none',color:"#373738" }} to='/ComingSoon'>
           Careers
          </Link></Nav.Link>

                  
                  
                  {/* <NavDropdown
                    title="Dropdown"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown> */}
                </Nav>
                {/* <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  /> */}
                  
                  {/* <Button className='btnContact'><a href='#formV' style={{textDecoration:'none',color:'white'}}>Contact Us</a></Button> */}
                {/* </Form> */}
                <Button className='btnContact' onClick={handleOpenModal}>
        Contact Us
      </Button>
                <Modal
        title="Contact Us"
        open={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <form ref={form} onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <label style={{ marginBottom: '0.5rem' }}>Name</label>
      <input type="text" name="user_name" style={{ padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #ccc', marginBottom: '1rem' }} />

      <label style={{ marginBottom: '0.5rem' }}>Email</label>
      <input type="email" name="email" style={{ padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #ccc', marginBottom: '1rem' }} />

      <label style={{ marginBottom: '0.5rem' }}>Phone Number</label>
      <input type="text" name="phoneNumber" style={{ padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #ccc', marginBottom: '1rem' }} />

      <label style={{ marginBottom: '0.5rem' }}>Message</label>
      <textarea name="message" style={{ padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #ccc', marginBottom: '1rem' }}></textarea>

      <input type="submit" value="Send" style={{ backgroundColor: '#37AF60', color: 'white', padding: '0.5rem 1rem', borderRadius: '0.25rem', border: 'none', cursor: 'pointer' }} />
    </form>

      </Modal>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Navbars;